#ReadArticle {
    background: #fff;
}

#ReadArticle > .position-relative > .AuthenticatedUserToolBar{
    padding: 0 22px;
}

#ReadArticle .flower-deco {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-image: url(/static/media/flowerdecoration-compressored.ae01de14.png);
    width: 100%;
    height: 90px;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;
}

#ReadArticle #subtitle{
    padding: 10px 1.1rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 300;
}

#ReadArticleContent{
    padding: 1rem;
    overflow-y: auto;
}

#ReadArticleContent h1{
    font-weight: 700;
    line-height: 2.1rem;
}

#ReadArticleContent h1 + p span{
    width: 2px;
    height: 100%;
    display: inline-block;
    background: #ddd;
    margin: 0 0.6rem;
}

#ReadArticleContent h1 + p{
    font-size: 0.9rem;
    font-weight: 300;
    letter-spacing: 0.07em;
    color: #b3b3b3;
}

#ReadArticleContent button.brand-color-pink-text{
    border: none;
    background: transparent;
    outline: none;
    color: #e65d57;
}

#ReadArticleContent > .LearningLabItem .LearningLabMedia{
    height: 220px;
    margin-bottom: 1rem;
}

#ReadArticleContent > .LearningLabItem.video .LearningLabMedia svg {
    height: 60px;
}

#ReadArticleContent > .LearningLabItem.pdf .LearningLabMedia svg{
    height: 60px;
}

#ReadArticleContent > .LearningLabItem.image .LearningLabMedia,
#ReadArticleContent > .LearningLabItem.meme .LearningLabMedia{
    height: auto;
    background: #000;
}

#ReadArticleContent > .LearningLabItem.image .LearningLabMedia img,
#ReadArticleContent > .LearningLabItem.meme .LearningLabMedia img{
    position: relative;
    max-height: 280px;
    object-fit: contain;
}

#detailMoreItems{
    margin: 0 -1rem;
}

#detailMoreItems:before{
    content: "";
    position: relative;
    display: block;
    margin: 1rem;
    margin-top: 3rem;
    border-top: 1px solid #ccc
}

#detailMoreItems > h2{
    font-size: 1.58rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

#detailMoreItems > div{
    padding: 0 1rem;
    overflow-x: auto;
}

#detailMoreItems > div:after{
    content: "as";
    opacity: 0;
    margin-left: -0.75rem;
}

#detailMoreItems .LearningLabItem{
    flex-shrink: 0;
    width: 60vw;
    margin-right: 0.7rem;
}

#learningLabActions + .LearningLabItem{
    margin-top: 1rem;
}

#learningLabActions button{
    padding: 0;
    background-color: transparent;
    border: none;
    margin-right: 2rem;
    font-size: 0.85rem;
    outline: none;
}

#learningLabActions button svg{
    height: 30px;
    width: 30px;
}

#learningLabActions button span{
    margin-left: 0.55rem;
    text-align: left;
}

#detailDescription a{
    color: #e65d57;
}

#detailDescription{
    font-size: 1.1rem;
    line-height: 1.6;
}

#learningLabActions + #detailDescription{
    margin-top: 1rem;
}

@media screen and (max-width: 400px){
    #ReadArticle .flower-deco {
        height: 93px;
    }
}

@media screen and (min-width: 730px){
    #ReadArticle{
        width: 580px;
        margin: 0 auto;
    }
}