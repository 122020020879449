.goodbye-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height:  100%;
  background-color: #FCEFF2;
}

.goodbye-screen img {
  max-width:  100%;
  max-height:  100%;
}
