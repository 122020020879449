html {
    --vh: 1vh;
}

body {
    margin: 0;
    font-family: "Museon Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@font-face {
    font-family: "Museon Sans";
    font-style: normal;
    font-weight: 900;
    src: url(../fonts/Museo_Sans/MuseoSans_900.otf);
}

@font-face {
    font-family: "Museon Sans";
    font-style: normal;
    font-weight: 100;
    src: url(../fonts/Museo_Sans/MuseoSans-100.otf);
}

@font-face {
    font-family: "Museon Sans";
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/Museo_Sans/MuseoSans_700.otf);
}

@font-face {
    font-family: "Museon Sans";
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/Museo_Sans/MuseoSans-300.otf);
}

@font-face {
    font-family: "Museon Sans";
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/Museo_Sans/MuseoSans-500.otf);
}


@font-face {
    font-family: "Museon Slab";
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/Museo_Slab/Museo_Slab_300.otf);
}

@font-face {
    font-family: "Museo Slab";
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/Museo_Slab/Museo_Slab_500.otf);
}

@font-face {
    font-family: "Sugar";
    font-style: normal;
    src: url(../fonts/Sugar-Vinegar.ttf);
}

.brand-color-pink {
    background-color: #EA5265 !important;
}

.brand-color-pink-text {
    color: #EA5265 !important;
}

.brand-color-pinkish {
    background-color: #E65F58 !important;
}

.disabled-background-color {
    background-color: rgb(204, 204, 204) !important;
}

.background-greenish {
    background-color: #44a09c;
}

    .brand-color-pinkish-text {
    color: #E65F58 !important;
}

.brand-color-orange {
    background-color: #E75412 !important;
}

.brand-color-orange-text {
    color: #E75412 !important;
}

.brand-color-blue {
    background-color: #717DBC !important;
}

.brand-color-blue-text {
    color: #717DBC !important;
}

.brand-color-orangeish {
    background-color: #EF8106 !important;
}

.brand-color-orangeish-text {
    color: #EF8106 !important;
}

.brand-color-green {
    background-color: #8BBF42 !important;
}

.brand-color-green-text {
    color: #8BBF42 !important;
}

.brand-color-yellow {
    background-color: rgba(255, 187, 0, 0.993) !important;
}

.z10 {
    z-index: 10;
}

.text-center{
    text-align: center;
}

.overflow-hidden{
    overflow: hidden;
}

.overflow-y-auto{
    overflow-y: auto;
}

/* POSITION HELPERS */
.position-absolute{
    position: absolute;
}

.position-relative{
    position: relative;
}

.position-fixed{
    position: fixed;
}

.pin-top, .pin-all{
    top: 0;
}

.pin-bottom, .pin-all{
    bottom: 0;
}

.pin-left, .pin-all{
    left: 0;
}

.pin-right, .pin-all{
    right: 0;
}

.d-inline{
    display: inline;
}

.d-block{
    display: block;
}

.d-inline-block{
    display: inline-block;
}

/* FLEX HELPERS */
.d-flex{
    display: flex;
}

.d-inline-flex{
    display: inline-flex;
}

.flex-col{
    flex-direction: column;
}

.flex-row{
    flex-direction: row;
}

.flex{
    flex: 1;
}

.flex-wrap{
    flex-wrap: wrap;
}

.center-center, .items-center{
    align-items: center;
}

.items-start{
    align-items: start;
}

.center-center, .justify-center{
    justify-content: center;
}

.justify-stretch{
    justify-content: stretch;
}

.justify-start{
    justify-content: flex-start;
}

.justify-end{
    justify-content: flex-end;
}

.justify-between{
    justify-content: space-between;
}

.justify-around{
    justify-content: space-around;
}

/* Font Size Helpers */
.large-page-title{
    font-size: 10.5vw !important;
}

.large-page-subtitle{
    font-size: 6.5vw;
    line-height: 1.2em;
    font-weight: 300;
    color: #777;
    padding: 0 1em;
}

.large-page-title + .large-page-subtitle{
    margin-top: 0.2em;
}

@media all and (min-width: 580px) {
    .large-page-title{
        font-size: 3em !important;
    }
    
    .large-page-subtitle{
        font-size: 1.95em;
        line-height: 1em;
        font-weight: 300;
        color: #777;
    }
    
    .large-page-title + .large-page-subtitle{
        margin-top: 0.2em;
    }
}

@media all and (max-width: 330px) {
    .large-page-title{
        font-size: 10vw !important;
    }

    .large-page-subtitle{
        font-size: 6.3vw;
        line-height: 1.35em;
    }
}


/* Overriding antd css */


/* customizing antd pagination component*/
.ant-pagination {
    padding: 10px;
    background: rgb(238, 239, 243);
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background: white;
    padding: 3px 15px;
    font-size: 1.34em;
    height: auto;
    margin-top: -5px;
    color: #e65d57;
    margin-right: 10px;
}

.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    margin-right: 0;
    margin-left: 10px;
}
.ant-pagination.mini .ant-pagination-item.ant-pagination-item-active{
    background: #e65d57;
    border-color: #e65d57;
    border-radius: 2px;
}
.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a, .ant-pagination-item-active a {
    color: #FFF;
}
.ant-pagination.mini .ant-pagination-item {

    padding: 10px 4px;
    height: 35px;
    font-weight: 700;
    line-height: 15px;
}

/* Keep chat widget hidden by default*/
#tawkchat-minified-box {
    display: none;
}