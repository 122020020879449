
.NewOpportunity .Toolbar{
    z-index: 10;
}


.NewOpportunity {
    padding-bottom: 80px;
    padding-top: 90px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: auto;
    background: #fff;
}

.NewOpportunity .welcome-text {
    text-align: center;
    padding-top: 20px;
}

.NewOpportunity .welcome-text h2 {
    font-size: 1.4em;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.025em;
    color: #555;
    font-weight: 300;
    /* margin-bottom: 0.6em; */
}

.NewOpportunity .welcome-text h2:first-child {
    font-size: 1.8em;
    margin-bottom: 0.2em;
    font-weight: 500;
}


.NewOpportunity .welcome-text p {
    font-size: 1.25em;
    font-weight: 300;
    color: #636363;
    margin-bottom: 0;
    letter-spacing: 0.03em;
}


.NewOpportunity form {
    padding:  10px 30px 20px 30px;
}

.NewOpportunity .form-item {
    margin-top: 30px;
}

.NewOpportunity .form-item:nth-child(2) {
    margin-top: 35px;
}

.NewOpportunity label {
    padding-bottom: 5px;
    font-size: 1.4em;
    color: #666;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0.15em;
}

.NewOpportunity label button{
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 0.85em;
    letter-spacing: 0.01em;
    outline: none;
}

.NewOpportunity label button:disabled{
    opacity: 0;
    pointer-events: none;
}

.NewOpportunity .CustomTextArea textarea {
    width: 100%;
    height: 150px;
    resize: none;
    outline: none;
    padding: 0.65em 0.9em;
    margin: auto;
    background: #fafafa;
    border-color: #cfcfcf;
    border-radius: 2px;
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.6;
}

.NewOpportunity #link .CustomInput input[type=text], .NewOpportunity textarea {
    border-radius: 6px;
    width: 100%;
    resize: none;
    outline: #e0493f;
    background: #fafafa;
    border-color: #cfcfcf !important;
    border-radius: 2px;
    font-weight: 500;
    font-size: 1.4em;
}

.NewOpportunity select {
    padding-left: 1.25em !important;
    background-position: right 1.1em top 50%, 0 0 !important;
    border: 1px solid #d8d6d6;
    background-color: #fafafa;
}

.NewOpportunity #imageAttachment {
    margin-top: 35px;
}

.NewOpportunity #attachments .CustomInput input[type='text'] {
    height: 35px;
    border-radius: 12px;
}

.NewOpportunity #attachments .CustomInput:nth-child(1), .NewOpportunity #attachments .CustomInput:nth-child(2) {
    width: 46%;
}

.NewOpportunity #submit-button .CustomButton{
    justify-content: center;
}

.NewOpportunity #submit-button .CustomButton:first-child button{
    background-color: transparent !important;
    color: rgb(224, 73, 63);
    margin-bottom: 1rem;
}

.NewOpportunity #submit-button .CustomButton:first-child button:disabled{
    color: #aaa;
    opacity: 0.7;
}

.NewOpportunity #link .info {
    text-align: center;
    font-size: 1em;
    font-weight: 900;
    height: 20px;
    width: 20px;
    color: #ffffff;
    border-radius: 50%;
    display: inline-block;
}

@media screen and (max-width: 400px){
    .NewOpportunity {
        padding-top: 60px;
    }

    .NewOpportunity .welcome-text h2 {
        font-size: 1.1em;
    }
    
    .NewOpportunity .welcome-text h2:first-child {
        font-size: 1.45em;
    }
    
    .NewOpportunity .welcome-text p {
        font-size: 1em;
        font-weight: 300;
        color: #828282;
        margin-bottom: -1.4em;
    }

    .NewOpportunity form {
        padding: 20px;
    }

    .NewOpportunity label {
        font-size: 1.1em;
        margin-bottom: 0.15em;
    }

    .NewOpportunity .CustomTextArea textarea {
        height: 130px;
        padding: 0.8em 1em;
        font-size: 1.15em;
    }

    .NewOpportunity select {
        font-size: 1.15em !important;
        padding: .75em 1em .75em 1.2em !important;
        border: 1px solid #d8d6d6 !important;
        background-color: #fafafa !important;
    }

    .NewOpportunity .CustomInput input[type='text'] {
        padding: 0.8em;
        font-size: 1.2em !important;
    }
}

@media screen and (min-width: 730px){
    .NewOpportunity,
    .NewOpportunity .AuthenticatedUserToolBar{
        width: 580px;
        margin: auto;
    }
}